* {
  padding: 0;
  margin: 0;
}

body {
  // overflow-y: hidden;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

header {
  display:flex;
  border-bottom: 1px solid lightgrey;
  
  position:fixed;
  background:white;
  // background:pink;
  width:100%;
  justify-content: space-between;

  // opacity:.9;
  top:0;
  #back-button {
    // flex-basis:30%;
    text-align:left !important;
    cursor: default;
    color:skyblue;
    font-weight: 100;
    padding: 1em;
    // padding-right:1em;
    &:active {
      background:lightgray;

    }
  }

  #total-pokemon {
    // padding:1em;
    background:skyblue;
    padding:1em;
    color:white;
    cursor:default;
  }
}
header h1 {
  // flex-basis:70%;
  margin-left:-2em;
  font-size: 1rem;
  text-align:center;
  padding: 1em 0;
  justify-self:center;
}

.main {
  height:100%;
  overflow-y:scroll;
  margin-top:3.5em;
  margin-bottom:3.5em;
  // margin: 30px auto 0;
  // background:pink;
  
}

.navbar {
  
  border-top: 1px solid lightgrey;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 1em;
  padding-top: 1em;
  background:white;
  
}


.navbar button {
  background: none;
  border: none;
  padding-right: 0.5em;
  outline: none;
}

a.pokemon {
  text-decoration: none;
  color:black;
  font-weight: 100;
}

li.pokemon {
  /* background:yellow; */
  display:flex;
  text-align:left;
  padding-left:.5em;
  // background:pink;
  // padding-bottom:1em;
  text-decoration:none !important;
  border-bottom:1px solid lightgrey;
  // color:darkgrey;
  cursor:default;
  img {
    width:50px;
    height:50px;
    pointer-events: none;
  }
  &:active {
    background:lightgrey;
  }  
  span {
    vertical-align: middle;
    // background:pink;
    padding-top:1em;
    padding-left:.5em;
    pointer-events: none;
  }
}



body::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.vis-hidden {
  visibility:hidden;
}

.vis-show {
  visibility: show;
}